import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  
  token: string;

  constructor(
    public router: Router,
    private _route: ActivatedRoute) { 
      this.token = '';
    }

  ngOnInit(): void {

    localStorage.removeItem('access_token');
    this.router.navigate(['']);
  }

}
